<template>
  <div class="dropdown" ref="root">
    <div class="dropdown__header" @click.prevent="toggleDropdown()">
      <slot name="header"> </slot>
    </div>
    <div
      ref="body"
      :class="[
        'dropdown__body',
        { 'dropdown__body--active': dropdownIsActive },
      ]"
    >
      <slot name="body" />

      <component
        :is="item.component"
        v-for="(item, index) in computedItems"
        :key="index"
        :href="item.href"
        :to="item.to"
        class="dropdown__body__item"
        @click="
          () => {
            item.onClick ? item.onClick() : ''
            toggleDropdown()
          }
        "
      >
        <component :is="item.icon" class="dropdown__body__item__icon" />
        {{ item.label }}
      </component>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, type PropType} from 'vue'
import { RouteMeta } from 'vue-router'
export default {
  name: 'mtDropdown',
  props: {
    items: {
      type: Array as PropType<
        {
          label: String
          to?: RouteMeta
          onClick?: () => void
          href?: string
          icon?: Component
        }[]
      >,
      default: [],
    },
  },
  data: () => ({
    dropdownIsActive: false,
  }),

  computed: {
    computedItems() {
      return this.items.map((item) => {
        let component = ''
        if (item.to) component = 'router-link'
        if (item.href) component = 'a'
        if (item.onClick) component = 'button'


        return {
          component: component,
          label: item.label,
          onClick: item.onClick,
          href: item.href ? item.href : '',
          to: item.to ? item.to : '',
          icon: item.icon ? item.icon : null,
        }
      })
    },
  },
  methods: {
    closeIfClickedOutside(event) {
      if (
        event.target === this.$refs.root ||
        event.composedPath().includes(this.$refs.root) ||
        event.target === this.$refs.body ||
        event.composedPath().includes(this.$refs.body)
      )
        return

      this.dropdownIsActive = false
    },
    toggleDropdown() {
      this.dropdownIsActive = !this.dropdownIsActive
      this.$emit('toggle', this.dropdownIsActive)
    },
  },
  mounted() {
    window.addEventListener('click', this.closeIfClickedOutside)
  },
  unmounted() {
    window.removeEventListener('click', this.closeIfClickedOutside)
  },
}
</script>

<style lang="scss" src="./AppDropdown.scss" />
