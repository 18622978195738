<template>
  <mt-dialog
      :opened-by-default="true"
      size="md"
      class="custom-assessment"
      @close="onClose()"
  >
    <mt-loading-animation-linear v-if="!loaded" />
    <template v-else>
      <slot name="header" />

      <mt-expander class="custom-assessment__expander">
        <mt-expander-item :disabled="expRespTask.materials.length === 0 && !expRespTask.description">
          <template #header>
            <h3 class="custom-assessment__expander__title">{{ expRespTask.name }} </h3>
          </template>
          <template #prependHeader>
            <app-tooltip>
              <template #activator>
                <mt-button
                    v-if="onPrev !== undefined"
                    :disabled="onPrev === false"
                    color="light-green"
                    icon
                    class="custom-assessment__expander__header__btn"
                    @click="() => changeTask(onPrev)"
                >
                  <chevron-left-icon :doubled="prevTaskIsOtherRespondent" />
                </mt-button>
              </template>

              {{ !onPrev ? 'Это самое первое задание' :  prevTaskIsOtherRespondent ? 'Предыдущий респондент' : 'Предыдущее задание' }}
            </app-tooltip>
          </template>
          <template #appendHeader>
            <app-tooltip>
              <template #activator>
                <mt-button
                    v-if="onNext !== undefined"
                    :disabled="onNext === false"
                    color="light-green"
                    icon
                    class="custom-assessment__expander__header__btn"
                    @click="() => changeTask(onNext)"
                >
                  <chevron-left-icon
                      :doubled="nextTaskIsOtherRespondent"
                      style="transform: rotate(0);"
                  />
                </mt-button>
              </template>

              {{ !onNext ? 'Это последнее задание' : nextTaskIsOtherRespondent ? 'Следующий респондент' : 'Следующее задание' }}
            </app-tooltip>
          </template>
          <template #content>
            <p
                class="custom-assessment__expander__text"
                v-show="expRespTask.description"
            >
              {{ expRespTask.description }}
            </p>
            <template v-if="expRespTask.materials.length">
              <h2 class="custom-assessment__expander__file-title">Файлы</h2>
              <div class="card mt-2">
                <file-download-card :links="expRespTask.materials"/>
              </div>
            </template>
          </template>
        </mt-expander-item>
      </mt-expander>

      <slot name="prepend-chat"/>

      <div class="custom-assessment__chat">
        <div class="custom-assessment__title">Ответ</div>
        <div
            class="custom-assessment__chat__item custom-assessment__chat__item_mark"
            v-if="mark"
        >
          <div class="custom-assessment__chat__item__header">
            <star-icon class="custom-assessment__chat__item__star"/>
            <h3 class="custom-assessment__chat__item__title">{{ mark.fullName }}</h3>
            <p class="custom-assessment__chat__item__date"> {{ mark.createdAt }}</p>
          </div>
          <div class="custom-assessment__chat__item__chips-row">
            <div
                class="custom-assessment__chat__item__chip"
                v-for="(scale, index) in mark.scales"
                :key="index"
            >
              {{ scale.name }}
              <span>{{ scale.value }}</span>
            </div>
          </div>
        </div>
        <mt-loading-animation-linear v-if="chatLoading"/>
        <div
            class="custom-assessment__chat__list"
            ref="chat"
        >
          <div
              class="custom-assessment__chat__item"
              v-for="message in chatMessages"
              :key="message.uuid"
          >
            <div class="custom-assessment__chat__item__header">
              <h3 class="custom-assessment__chat__item__title">{{ message.user.fullName }} ({{ message.user.role }})</h3>
              <p class="custom-assessment__chat__item__date">{{ message.createdAt }}</p>
            </div>
            <p class="custom-assessment__chat__item__text">{{ message.value }}</p>
            <file-download-card
                class="mt-2"
                :links="message.files"
                color="#fff"
            />
          </div>
        </div>
        <file-download-card
            class="custom-assessment__input-box__files"
            :links="newMessage.files"
            link-label="name"
            disabled
            v-show="newMessage.files.length"
        >
          <h4 class="custom-assessment__input-box__files__title">Файлы для отправки</h4>
          <template #item-append="{index}">
            <trash-icon
              class="custom-assessment__input-box__files__item__delete"
              @click="deleteFile(index)"
            />
          </template>
        </file-download-card>
      </div>
      <mt-loading-animation-linear
        :class="[
          'custom-assessment__message-send-loading',
          {
            'custom-assessment__message-send-loading_show': sendMessageRequestPending
          }
        ]"
      />
      <div class="custom-assessment__input-box">
        <input
            multiple="multiple"
            type="file"
            @change="uploadFiles"
            style="display: none;"
            ref="newMessageFileInput"
        />
        <app-textarea
            ref="textarea"
            class="custom-assessment__input-box__textarea"
            :loading="sendMessageRequestPending"
            @submit="callSendMessageAction()"
            placeholder="Отправить сообщение ..."
            :initial-height="25"
            v-model="newMessage.message"
        />
        <mt-button
            icon
            color="light-green"
            class="custom-assessment__input-box__btn"
            @click="$refs.newMessageFileInput.click()"
        >
          <link-icon />
        </mt-button>
        <mt-button
            icon
            class="custom-assessment__input-box__btn"
            @click="callSendMessageAction()"
            color="light-green"
            :disabled="sendMessageRequestPending"
        >
          <send-icon />
        </mt-button>
      </div>

      <confirmation-dialog
          v-model="isConfirmationDialogOpen"
          text="Вы уверены, что хотите перейти к другому заданию?"
          submit-text="Перейти"
          :handler="submitHandlerForConfirmationDialog"
      >
        <template #title>
          Введенное вами сообщение будет потеряно
        </template>
      </confirmation-dialog>
    </template>
    <i v-show="error">{{ error }}</i>
  </mt-dialog>
</template>

<script>
import MtExpander from '@/components/UI/mtExpander/mtExpander.vue';
import MtExpanderItem from '@/components/UI/mtExpander/mtExpanderItem.vue';
import MtSvg from '@/components/UI/mtSvg/index.vue';
import fileDownloadCard from '@/components/workspace/assessmentPlans/plans/fileDownloadCard/index.vue';
import {mapActions, mapState} from 'vuex';
import MtDialog from '@/components/UI/mtDialog/mtDialog.vue';
import MtLoadingAnimationLinear from '@/components/UI/mtLoadingAnimationLinear/mtLoadingAnimationLinear.vue';
import AppTextarea from "@/components/UI/AppTextarea/AppTextarea.vue";
import SendIcon from "@/components/icons/SendIcon.vue";
import LinkIcon from "@/components/icons/LinkIcon.vue";
import TrashIcon from "@/components/icons/TrashIcon.vue";
import StarIcon from "@/components/icons/StarIcon.vue";
import ChatApi from "@/api/chat.api";
import MtButton from "@/components/UI/mtButton/index.vue";
import ChevronLeftIcon from "@/components/icons/ChevronLeftIcon.vue";
import AppTooltip from "@/components/UI/AppTooltip/AppTooltip.vue";
import ConfirmationDialog from "@/components/composite/confirmationDialog/confirmationDialog.vue";

export default {
  name: "customAssessment",
  components: {
    ConfirmationDialog,
    AppTooltip,
    ChevronLeftIcon,
    MtButton,
    StarIcon,
    TrashIcon,
    LinkIcon,
    SendIcon,
    AppTextarea,
    MtLoadingAnimationLinear,
    MtDialog,
    fileDownloadCard,
    MtSvg,
    MtExpanderItem,
    MtExpander
  },
  props: {
    onMount: Function,
    nextTask: {
      type: [Object, Boolean],
      default: false
    },
    onNext: {
      type: [Function, Boolean],
      default: undefined
    },
    prevTask: {
      type: [Object, Boolean],
      default: false
    },
    onPrev: {
      type: [Function, Boolean],
      default: undefined
    }
  },
  data: () => {
    return {
      loaded: false,
      error: null,
      sendMessageRequestPending: false,
      chatLoading: false,
      chatObserver: null,
      newMessage: {
        message: '',
        files: []
      },
      meta: {},
      chatMessages: [],
      isConfirmationDialogOpen: false,
      submitHandlerForConfirmationDialog: null
    }
  },
  computed: {
    ...mapState('assessmentPlans/plan/task', ['expRespTask', 'mark', 'sessionUuid']),

    nextTaskIsOtherRespondent() {
      if(!this.nextTask) return false;
      return this.nextTask.respondentUuid !== this.$route.params.respondentUuid;
    },

    prevTaskIsOtherRespondent() {
      if(!this.prevTask) return false;
      return this.prevTask.respondentUuid !== this.$route.params.respondentUuid;
    },
  },
  methods: {
    ...mapActions('assessmentPlans/plan/task', ['fetchExpRespTask', 'getMark']),
    onClose() {
      let routeName;
      switch (this.$route.name) {
        case "ExpertTaskSingle":
          routeName = 'ExpRespAssessmentPlanSingle';
          break;
        case "RespondentTaskSingle":
          routeName = 'ExpRespAssessmentPlanSingle';
          break;
        case "RespondentCabinetTaskSingle":
          routeName = 'RespondentCabinet';
          break;
        default:
          routeName = 'AssessmentPlanSingleResults';
      }

      this.$router.push({
        name: routeName,
        params: {
          uuid: this.$route.params.planUuid
        }
      })
    },
    uploadFiles(event) {
      let files = event.target.files;
      this.newMessage.files = [...this.newMessage.files, ...files];
    },
    deleteFile(index) {
      this.newMessage.files.splice(index, 1);
    },
    callSendMessageAction() {
      if (this.sendMessageRequestPending) return;

      if (this.newMessage.files.length === 0 && !this.newMessage.message) {
        return;
      }

      this.sendMessageRequestPending = true;
      ChatApi.sendMessage(
          this.$route.params.planUuid,
          this.$route.params.taskUuid,
          this.sessionUuid,
          this.newMessage,
      )
          .then((message) => {
            this.newMessage.message = '';
            this.newMessage.files = [];
            this.chatMessages.push(message);
            this.scrollToEndOfChat()
            setTimeout(() => {
              this.$refs.textarea.calculateHeight();
            })
          })
          .catch((error) => {
            if (error?.data?.message) {
              this.$toast.error(error?.data?.message)
            }
          })
          .finally(() => {
            this.sendMessageRequestPending = false;
          });
    },
    scrollToEndOfChat() {
      this.$nextTick(() => {
        const chat = this.$refs.chat;
        chat.scroll(0, chat.scrollHeight);
      });
    },
    chatObserve() {
      const chat = this.$refs.chat;
      const firstChild = chat.children[0]

      if (!firstChild) return;

      this.chatObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const isExistNextPage = this.meta.current_page < this.meta.last_page
            if (isExistNextPage && !this.chatLoading) {
              this.loadMessages(this.meta.current_page + 1)
              chat.scrollTop = chat.children[0].offsetTop
              this.chatObserver.unobserve(firstChild)
            }
          }
        })
      })
      this.chatObserver.observe(firstChild)
    },
    startChatResizeObserver() {
      const chat = this.$refs.chat;
      const resizeObserver = new ResizeObserver(entries => {
        entries.forEach(({target}) => {
          const scrollHeight = target.scrollHeight;
          const clientHeight = target.clientHeight;
          chat.style.paddingRight = scrollHeight > clientHeight ? '10px' : 0;
        })
      })
      resizeObserver.observe(chat)
    },
    changeTask(cb) {
      if(this.newMessage.message || this.newMessage.files.length) {
        this.submitHandlerForConfirmationDialog = async () => cb();
        this.isConfirmationDialogOpen = true;
      } else {
        cb();
      }
    },
    async loadMessages(page = 1) {
      this.chatLoading = true

      if (page !== 1) this.chatObserve()

      return await ChatApi.getMessages(
          this.$route.params.planUuid,
          this.$route.params.taskUuid,
          this.sessionUuid,
          {
            page
          }
      )
          .then(response => {
             this.chatLoading = false
             this.chatMessages = [...response.data.data.reverse(), ...this.chatMessages]
             this.meta = response.data.meta
          })
          .finally(() => {
            this.chatLoading = false
          })
    },
  },
  mounted() {
    setTimeout(async () => {
      Promise.all([
        await this.onMount(),
        await this.fetchExpRespTask({
          uuidPlan: this.$route.params.planUuid,
          uuid: this.$route.params.taskUuid
        }),
        this.getMark({
          planUuid: this.$route.params.planUuid,
          taskUuid: this.$route.params.taskUuid,
          sessionUuid: this.sessionUuid,
        }),
        this.loadMessages()
      ])
          .then(() => {
            this.loaded = true;

            this.$nextTick(() => {
              this.scrollToEndOfChat();
              this.startChatResizeObserver();
              this.chatObserve();
            })
          })
    })
  }
}
</script>

<style
    lang="scss"
    src="./style.scss"
/>